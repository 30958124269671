





import useStore from "@/use/useStore"
import { computed, defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "Lobby",
  components: {
    LobbyDesktop: () => import("@/pages/Lobby/LobbyDesktop.vue"),
    LobbyMobile: () => import("@/pages/Lobby/LobbyMobile.vue")
  },
  setup() {
    const { store } = useStore()
    const isMobile = computed(() => store.getters["Mobile/mobile"])

    return { isMobile }
  }
})
